<template>
  <div class="container">
    <section class="blog-content">
      <ProductArticle v-for="article in articles" :data="article" :key="article.id"/>
    </section>
  </div>
</template>

<script>
import PublicAPI from "@/api/PublicAPI";

export default {
  components: {
    ProductArticle: () => import("@/components/product/ProductArticle.vue"),
  },
  data() {
    return {
      articles: [
        {
          id: "101",
          title: "Наши новые продукты",
          image: "temp-img.svg",
          description:
            "Мы запустили линейку новых продуктов о которых спешим вам рассказать.",
          tags: ["Витамины", "Новинки"],
        },
        {
          id: "102",
          title: "Наши старые продукты",
          image: "temp-img.svg",
          description:
            "Мы запустили линейку новых продуктов о которых спешим вам рассказать.",
          tags: ["Витамины", "Новинки"],
        },
      ],
    };
  },
  async mounted() {
    // const blogData = await this.$axios("https://back.rizaherb.kz/index.php?route=feed/rest_api/getblog")
    const blogData = await PublicAPI.getBlogData("feed/rest_api/getblog");
    this.articles = blogData
  }
};
</script>

<style scoped>
.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

.blog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 60px;
  padding-bottom: 60px;
}
</style>